import React from 'react'
import { Link } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import bcs from '../Images/schools/bcs_logo.png'
import nisi from '../Images/schools/nissi.png'
import oneworld from '../Images/schools/oneworldlogo.png'
import mcs from '../Images/schools/logo_mcs.png'
import bright from '../Images/schools/classrecordheader.png'




function Project() {

    return (
        <React.Fragment>
            <div className="container mx-auto">
                <h4 className='flex justify-start  sm:text-lg text-gray-300'>Web Based & Mobile 2D Game App - Software Engineer</h4>
                <div className="row">
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="1 - Web Based" severity='info'></Badge>}
                        >
                            <Link className='no-underline' to={'/BFP'}><small className=' cursor-pointer'>Web Portal and Decision Support System for the Bureau of Fire Protection</small></Link>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="2 - Web Based" severity='info'></Badge>}>
                            <Link className='no-underline' to={'/archives'}><small className='cursor-pointer'>Web-based Thesis & Research Archive for College Students in Father Saturnino Urios University</small></Link>

                        </Card>

                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="3 - Mobile 2D Game App" severity='info'></Badge>}>
                            <Link className='no-underline' to={'/falchion'}><small className='cursor-pointer'>RPG 2D Game Android Falchion Using Unity Software</small></Link>
                        </Card>

                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="4 - Web Based" severity='info'></Badge>}>
                            <Link className='no-underline' to={'/chatbot'}><small className='cursor-pointer'>ANECO Chatbot Consumer Complaint Management Designed to Provide Insights and Proactive Solutions
                            </small></Link>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="5 - Web Based" severity='info'></Badge>}>
                            <Link className='no-underline' to={'/cocobid'}><small className='cursor-pointer'> Coconut Bidding System for Local Coconut Farmers in
                                Caraga Region XIII
                            </small></Link>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="6 - Web Based" severity='info'></Badge>}>
                            <Link className='no-underline' to={'/thesis'}><small className='cursor-pointer'> Thesis Management System  at Northwestern Mindanao State College of Science and Technology
                            </small></Link>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="7 - Web Based" severity='info'></Badge>}>
                            <Link className='no-underline' to={'/inventory'}><small className='cursor-pointer'> Enhancing Inventory System At JVV Marketing Corporation</small></Link>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="8 - Web Based" severity='info'></Badge>}>
                            <Link className='no-underline' to={'/enhancement'}><small className='cursor-pointer'> Enhancing Creativity through AI: A Development of Auto-generated Floral Design and Ordering System</small></Link>
                        </Card>
                    </div>

                </div>
            </div>
            <Divider></Divider>
            <div className="container mx-auto">
                <h4 className='flex justify-start  sm:text-lg text-gray-300'>Web Based Own Project</h4>
                <div className="row">
                    <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                        <Card subTitle={<Badge value="1 - Web Based" severity='info'></Badge>}
                        >
                            <Link className='no-underline'><small className=' cursor-pointer'>Web-Based Automated DTR & Payroll Management System</small></Link>
                        </Card>
                    </div>
                </div>
            </div>

            <Divider></Divider>

            <div className="container mx-auto mt-4">
                <h4 className='flex justify-start  sm:text-lg text-gray-300'>iTECH-RAR Solutions, Inc. - Junior Developer</h4>
                <div className="mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="1 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                    Bethany Christian School System in Gakkousoft
                                    </small></Link>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="2 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                    Oneworld Montessori house School System in Gakkousoft
                                    </small></Link>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="3 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                    Nissi Academy School System in Gakkousoft
                                    </small></Link>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="4 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                    Bethany Christian School System in Gakkousoft
                                    </small></Link>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="5 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                    Bright Academy School System in Gakkousoft
                                    </small></Link>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="6 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                    Mandaue Christian Schools School System in Gakkousoft
                                    </small></Link>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="7 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                    Enterprise Resources Planning System 
                                    </small></Link>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="8 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                    Notre Dame of Dadiangas University  School System in Gakkousoft
                                    </small></Link>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="9 - Web Based" severity='info'></Badge>}>
                                    <Link className='no-underline' ><small className='cursor-pointer'> 
                                        Web Based DTR System with Face Recognition,Finger Print Scanner, ID Scanner
                                    </small></Link>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider></Divider>

            <div className="container mx-auto mt-4">
                <h4 className='flex justify-start  sm:text-lg text-gray-300'>ICargo Pacific Technologies - Full Stack Developer</h4>
                <div className="mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-12 col-md-4 mb-2">
                                <Card subTitle={<Badge value="1 - Web Based" severity='info'></Badge>}
                                >
                                    <Link className='no-underline'><small className=' cursor-pointer'>AI Logistics Solutions Management System 2023</small></Link>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}


export default Project