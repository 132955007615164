import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import React from 'react'
import { FaBootstrap, FaCss3, FaHtml5, FaLaravel, FaNpm, FaReact } from 'react-icons/fa'
import { Link } from 'react-router-dom'


function BFP(props) {


    return (
        <React.Fragment>
            <div id='project1'>
                <div className="container mx-auto mt-14">
                    <div className="grid grid-flow-row-dense grid-cols-1 ">
                        <div className="shadow-2xl overflow-hidden max-w-full">
                            <div className="container">
                                <Badge value="1"></Badge>
                                <h4 className='text-white sm:text-base md:text-lg text-justify'>Web Portal and Decision Support System for the Bureau of Fire Protection</h4>
                                <p className='text-parag'>Integrate a web portal to the information system to help the constituents avail the services of BFP remotely, and allow them to report hazards/ incidents at their location. And develop a decision support system that can help the agency to store and visualize the data. Will have an efficient method to process, store and retrieve data involved in the services of the agency. They will also become capable of tracking incidents, generating reports and data visualizations, and assisting them in making decisions.</p>
                                <Divider><h4 className='text-white'>Features</h4></Divider>

                                <div className="container">
                                    <div className="grid md:grid-cols-4 gap-3 md:grid-rows-3 sm:grid-cols-1 grid-flow-row-dense">
                                        <div className='text-white'>Decision Support System</div>
                                        <div className='text-white'>Integrate API's (Facebook, Gmail,Recapcha,Google Map)</div>
                                        <div className='text-white'>Apply permits through online</div>
                                        <div className='text-white'>Data Analytics</div>
                                        <div className='text-white'>Email Notification</div>
                                        <div className='text-white'>User Can Report Hazard / Incident</div>
                                        <div className='text-white'>Data Tracking</div>
                                        <div className='text-white'>7 Types of Accounts</div>
                                    </div>
                                </div>
                                <Divider> <h4 className='text-white'>Tools & Language</h4></Divider>

                                <div className="container mx-auto">
                                    <div className="grid grid-cols-4 grid-flow-row-dense gap-2 grid-rows-3">
                                        <FaHtml5 className='' size={80} style={{ color: "#F36620" }}></FaHtml5>
                                        <FaCss3 size={80} style={{ color: "#2083F3" }}></FaCss3>
                                        <FaReact size={80} style={{ color: "#2083F3" }}></FaReact>
                                        <FaLaravel size={80} style={{ color: "#F33020" }}></FaLaravel>
                                        <FaNpm size={80} style={{ color: "#B3ABB8" }}></FaNpm>
                                        <FaBootstrap size={80} style={{ color: "#A020F3" }}></FaBootstrap>
                                    </div>
                                </div>
                                
                             
                                <div className=''>
                                    <Link to={'/projects'}><Button className='p-button-sm p-button-info' label='Return Page'></Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default BFP