import React from 'react'
import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
function Testemonial() {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 7000,
        cssEase: "linear"
    };

    return (
        <React.Fragment>
            <h4 className='text-center text-lime-50 tracking-widest uppercase'>testimonial</h4>
            <div className="slider-container">
                <Slider {...settings}>
                    <section class="bg-transparent dark:bg-gray-900">
                        <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                            <figure class="max-w-screen-md mx-auto">
                                <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                                </svg>
                                <blockquote>
                                    <p class="text-lg font-light italic  text-slate-200 ">"He developed our logistic company's project from scratch and supported us from start to finish, exceeding our expectations in every way. He provided a personal and friendly experience, and his ability to creatively present our key messages gave us immense confidence in him. He often understood our client's website needs better than we did and perfectly complemented our vision. He is reliable, professional, and easy to work with. We highly recommend him and look forward to continuing our working relationship."</p>
                                </blockquote>
                                <figcaption class="flex items-center justify-center mt-6 space-x-3">
                                    <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                        <div class="pr-3 font-medium text-gray-300">Justin San</div>
                                        <div class="pl-3 text-sm font-light text-gray-300">CEO @ ICargo Pacific Technology</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </section>
                    <section class="bg-transparent dark:bg-gray-900">
                        <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                            <figure class="max-w-screen-md mx-auto">
                                <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                                </svg>
                                <blockquote>
                                    <p class="text-lg font-light italic  text-slate-200 ">"He was the one who helped me develop the UI/UX for the logistics system. I also supported him as a tester, and from my experience, I can see that he is capable of developing solutions for the system."</p>
                                </blockquote>
                                <figcaption class="flex items-center justify-center mt-6 space-x-3">
                                    <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                        <div class="pr-3 font-light text-gray-200 ">Daryl Nicole</div>
                                        <div class="pl-3 text-sm font-light text-gray-300">Web Designer @ ICargo Pacific Technology</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </section>

                </Slider>
            </div>
        </React.Fragment>
    )
}

export default Testemonial