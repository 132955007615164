import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Page404 from './components/error/Page404'
import LandingPage from './components/LandingPage'
import Project from './components/Project'
import BFP from './components/Project/BFP'


function App() {
    return (
        // <LandingPage>
            
        // </LandingPage>
        <Router>
            <Switch>
                {/* <Route path={'/'} exact component={LandingPage}></Route>
                <Route path={'/projects'} exact component={Project}></Route>
                <Route path={'/BFP'} exact component={BFP}></Route> */}

                <Route path={'/'} name="Index" render={(props) => <LandingPage {...props} />}></Route>

                <Route component={Page404}></Route>
            </Switch>
        </Router>
    )
}

export default App