import { Divider } from 'primereact/divider'
import React, { lazy, Suspense, Component } from 'react'
import CarouselImages from './CarouselImages'
import Myself from './Myself'
import NavBar from './NavBar'
import BFP from './Project/BFP'
import Typewriter from 'typewriter-effect';
import AboutMe from './AboutMe'
import { Redirect, Route, Switch } from 'react-router-dom'
import PublicRoutes from './routes/PublicRoutes'


function LandingPage() {
    return (
        <React.Fragment>
            <NavBar></NavBar>
            <div class="w-full bg-cover bg-center img-banner h-[50vh]">
                <div class="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-80">
                    <div class="text-center">
                        <h1 class="text-white lg:text-5xl font-semibold tracking-widest uppercase md:text-3xl">Georgie Recabo</h1>
                        <span className='text-white text-3xl tracking-wide flex justify-center items-center gap-x-3'>I am a <Typewriter
                            options={{
                                strings: ['Full Stack Developer', 'Consultant'],
                                autoStart: true,
                                loop: true,
                            }}
                        /></span>
                    </div>
                </div>
            </div>
            
            <div className="container mx-auto mt-3 p-6">
                <Switch>
                    {
                        PublicRoutes.map((route, idx) => {
                            return (
                                route.components && (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={(props) =>
                                            <route.components {...props} />
                                        }
                                    />
                                )
                            )
                        })
                    }
                    <Redirect from='/' to={'/'} />
                </Switch>
                
            </div>
        </React.Fragment>
    )
}

export default LandingPage