import { Badge } from 'primereact/badge'
import { Divider } from 'primereact/divider'
import React from 'react'
import Carousel from 'react-grid-carousel'
import Record from '../../Images/Json/Cocobid.json'
import { FaBootstrap, FaCss3, FaHtml5, FaLaravel, FaNpm, FaReact } from 'react-icons/fa'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { Image } from 'primereact/image'

function Thesis() {

    const layout = [
        {
            breakpoint: 800,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 1500,
            showDots: true,
        }
    ]

    // console.log(Record);

    return (
        <React.Fragment>
            <div id='archives'>
                <div className="container mx-auto">
                    <Badge value="2"></Badge>
                    <h4 className='text-white sm:text-base md:text-lg text-justify'>Thesis Management System  at Northwestern Mindanao State College of Science and Technology</h4>
                    <p className='text-parag'>
                        The project aims to
                        develop a complete system that improves workflow procedures while ensuring compliance with
                        regulatory requirements and solving the issues experienced by students and administrators
                        participating in the thesis process. The project focuses on creating and constructing a Thesis
                        management system that includes functions like uploading thesis files, storing documents, posting
                        announcements, viewing capabilities, and more. By working on this study, it is expected that
                        participants taking part in thesis management activities will be more efficient as an outcome of the
                        implementation of a targeted thesis management system. The system's computerized functions will
                        make it easier to handle papers manually.
                    </p>
                </div>
                <Divider>   <h4 className='text-white'>Features & Reference</h4></Divider>

                <div className="container">
                    <div className="grid md:grid-cols-4 gap-3 md:grid-rows-3 sm:grid-cols-1 grid-flow-row-dense">
                        <div className='text-white'>Search Engine</div>
                        <div className='text-white'>Integrate API's (Gmail,Recapcha,Number of Visits)</div>
                        <div className='text-white'>Stored Thesis Files</div>
                        <div className='text-white'>Data Analytics</div>
                    </div>
                </div>
                <Divider><h4 className='text-white'>Tools & Language</h4></Divider>

                <div className="container mx-auto">
                    <div className="grid grid-cols-4 grid-flow-row-dense gap-4 grid-rows-3">
                        <FaHtml5 className='' size={80} style={{ color: "#F36620" }}></FaHtml5>
                        <FaCss3 size={80} style={{ color: "#2083F3" }}></FaCss3>
                        <FaReact size={80} style={{ color: "#2083F3" }}></FaReact>
                        <FaLaravel size={80} style={{ color: "#F33020" }}></FaLaravel>
                        <FaNpm size={80} style={{ color: "#B3ABB8" }}></FaNpm>
                        <FaBootstrap size={80} style={{ color: "#A020F3" }}></FaBootstrap>
                    </div>
                </div>
                <Divider></Divider>
                <Carousel cols={2} rows={1} gap={20} loop autoplay={3500} showDots={true} responsiveLayout={layout}>
                    {
                        Record.map((img) => {
                            return (
                                <Carousel.Item>
                                    <Image src={img.image}  width='100%' preview />
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
                <Divider></Divider>
                <Link to={'/projects'}><Button className='p-button-sm p-button-info' label='Return Page'></Button></Link>

            </div>

        </React.Fragment>
    )
}

export default Thesis