import { Badge } from 'primereact/badge'
import { Divider } from 'primereact/divider'
import React from 'react'
import Carousel from 'react-grid-carousel'
import Record from '../../Images/Json/IMS.json'
import { FaBootstrap, FaCss3, FaHtml5, FaLaravel, FaNpm, FaReact } from 'react-icons/fa'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { Image } from 'primereact/image';


function Invemtory() {

    const layout = [
        {
            breakpoint: 800,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 1500,
            showDots: true,
        }
    ]

    // console.log(Record);

    return (
        <React.Fragment>
            <div id='archives'>
                <div className="container mx-auto">
                    <Badge value="2"></Badge>
                    <h4 className='text-white sm:text-base md:text-lg text-justify'>
                        Enhancing Inventory System And Efficiency At JVV Marketing Corporation
                    </h4>
                    <p className='text-parag'>
                        JVV Marketing wants to improve inventory management and operational efficiency in order to maintain competitiveness and promote development. Purchasing sophisticated inventory management software is a crucial strategy for order management, demand forecasting, and centralized tracking.
                    </p>
                </div>
                <Divider>   <h4 className='text-white'>Features & Reference</h4></Divider>

                <div className="container">
                    <p className="text-parag">
                        Deploy a real-time inventory visibility system: Design and implement a real-time inventory visibility system that provides timely and accurate information about inventory levels across multiple locations and warehouses. This system should enable stakeholders to access up-to-date inventory data, track stock movements, and make informed decisions regarding stock replenishment and allocation.
                    </p>
                    <p className="text-parag">
                    System will give admins a clear view of how the company manages its inventory. The main aim is to make things easier to understand and help people make better decisions by generating reports that are dynamic and easy to interact with. 

                    </p>
                </div>
                <Divider><h4 className='text-white'>Tools & Language</h4></Divider>

                <div className="container mx-auto">
                    <div className="grid grid-cols-4 grid-flow-row-dense gap-4 grid-rows-3">
                        <FaHtml5 className='' size={80} style={{ color: "#F36620" }}></FaHtml5>
                        <FaCss3 size={80} style={{ color: "#2083F3" }}></FaCss3>
                        <FaReact size={80} style={{ color: "#2083F3" }}></FaReact>
                        <FaLaravel size={80} style={{ color: "#F33020" }}></FaLaravel>
                        <FaNpm size={80} style={{ color: "#B3ABB8" }}></FaNpm>
                        <FaBootstrap size={80} style={{ color: "#A020F3" }}></FaBootstrap>
                    </div>
                </div>
                <Divider></Divider>
                <Carousel cols={2} rows={1} gap={20} loop autoplay={3500} showDots={true} responsiveLayout={layout}>
                    {
                        Record.map((img) => {
                            return (
                                <Carousel.Item>
                                    <Image src={img.image} width='100%' preview />
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
                <Divider></Divider>
                <Link to={'/projects'}><Button className='p-button-sm p-button-info' label='Return Page'></Button></Link>

            </div>

        </React.Fragment>
    )
}

export default Invemtory