import { Badge } from 'primereact/badge'
import { Divider } from 'primereact/divider'
import React from 'react'
import Carousel from 'react-grid-carousel'
import Record from '../../Images/Json/Chatbot.json'
import { FaBootstrap, FaCss3, FaHtml5, FaLaravel, FaNpm, FaReact } from 'react-icons/fa'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { SiDialogflow } from "react-icons/si";
import { Image } from 'primereact/image';

function Chatbot() {

    const layout = [
        {
            breakpoint: 800,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 1500,
            showDots: true,
        }
    ]

    return (
        <React.Fragment>
            <div id='archives'>
                <div className="container mx-auto">
                    <Badge value="2"></Badge>
                    <h4 className='text-white sm:text-base md:text-lg text-justify'>ANECO: Consumer
                        Complaint Management with Chatbot Designed
                        to Provide Insights
                        and Proactive Solutions
                    </h4>
                    <p className='text-parag'>To develop and to provide the administrators of ANECO a consumer complaint management with a chatbot. With the chatbot being the receiver of the complaints, it will help out the intended ANECO administrators. A chatbot system which allows to collect and gather large amounts of data from the consumer types of queries and issues that will be put in the analytics to see and monitor the current cases. Its purpose is for the aneco admin to utilize it to properly organize and add services to the internal employees and external customers to boost the operational efficiency bringing or offering convenient ways while reducing the need for human interaction.</p>
                </div>
                {/* <Divider>   <h4 className='text-white'>Features & Reference</h4></Divider>

                <div className="container">
                    <div className="grid md:grid-cols-4 gap-3 md:grid-rows-3 sm:grid-cols-1 grid-flow-row-dense">
                        <div className='text-white'>Chatbot</div>
                        <div className='text-white'>Integrate API's (DialogFlow, Kommunicate )</div>
                        <div className='text-white'>Analytics Drill Down</div>
                        <div className='text-white'>Booking System</div>
                    </div>
                </div> */}
                <Divider><h4 className='text-white'>Tools & Language</h4></Divider>

                <div className="container mx-auto">
                    <div className="grid grid-cols-4 grid-flow-row-dense gap-4 grid-rows-3">
                        <FaHtml5 className='' size={80} style={{ color: "#F36620" }}></FaHtml5>
                        <FaCss3 size={80} style={{ color: "#2083F3" }}></FaCss3>
                        <FaReact size={80} style={{ color: "#2083F3" }}></FaReact>
                        <FaLaravel size={80} style={{ color: "#F33020" }}></FaLaravel>
                        <FaNpm size={80} style={{ color: "#B3ABB8" }}></FaNpm>
                        <FaBootstrap size={80} style={{ color: "#A020F3" }}></FaBootstrap>
                        <SiDialogflow size={80} style={{ color: "#F36620" }}></SiDialogflow>
                    </div>
                </div>
                <Divider></Divider>
                <Carousel cols={2} rows={1} gap={20} loop autoplay={3500} showDots={true} responsiveLayout={layout}>
                    {
                        Record.map((img) => {
                            return (
                                <Carousel.Item>
                                    <Image src={img.image}  preview width='100%'/>
                                    {/* <img src={img.image} alt="" width="100%" /> */}
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
                <Divider></Divider>
                <Link to={'/projects'}><Button className='p-button-sm p-button-info' label='Return Page'></Button></Link>

            </div>

        </React.Fragment>
    )
}

export default Chatbot