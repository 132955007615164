import { Badge } from 'primereact/badge'
import { Divider } from 'primereact/divider'
import React from 'react'
import Carousel from 'react-grid-carousel'
import Record from '../../Images/Json/Cocobid.json'
import { FaBootstrap, FaCss3, FaHtml5, FaLaravel, FaNpm, FaReact } from 'react-icons/fa'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { Image } from 'primereact/image'

function Cocobid() {

    const layout = [
        {
            breakpoint: 800,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 1500,
            showDots: true,
        }
    ]

    // console.log(Record);

    return (
        <React.Fragment>
            <div id='archives'>
                <div className="container mx-auto">
                    <Badge value="2"></Badge>
                    <h4 className='text-white sm:text-base md:text-lg text-justify'>Coconut Bidding System for Local Coconut Farmers in
                                Caraga Region XIII</h4>
                    <p className='text-parag'>
                    Cocobid constitutes an online auction system, functioning as a website where farmers can enlist the products they intend to sell. Its development aims to facilitate equitable transactions between buyers and farmers by means of an auction mechanism. Within this system, buyers suggest prices for farmers' products, aligning with the established Suggested Retail Price (SRP). Notably, the bidding process adheres to the stipulation that buyers are precluded from bidding below the SRP; their bids must meet or exceed the SRP. Successful bidders receive real-time notifications upon winning the item they bid on. Moreover, Cocobid is accessible on mobile devices, boasting a responsive website design that concurrently prioritizes user security and privacy in accordance with their respective roles.
                    </p>
                </div>
                <Divider>   <h4 className='text-white'>Features & Reference</h4></Divider>
             
                <div className="container">
                    <div className="grid md:grid-cols-4 gap-3 md:grid-rows-3 sm:grid-cols-1 grid-flow-row-dense">
                        <div className='text-white'>Search Engine</div>
                        <div className='text-white'>Integrate API's (Gmail,Recapcha,Google Map)</div>
                        <div className='text-white'>Analytical System</div>
                        <div className='text-white'>Account Rating</div>
                        <div className='text-white'>Monthly Profit Income</div>
                        <div className='text-white'>Dynamic Products</div>

                    </div>
                </div>
                <Divider><h4 className='text-white'>Tools & Language</h4></Divider>
                
                <div className="container mx-auto">
                    <div className="grid grid-cols-4 grid-flow-row-dense gap-4 grid-rows-3">
                        <FaHtml5 className='' size={80} style={{ color: "#F36620" }}></FaHtml5>
                        <FaCss3 size={80} style={{ color: "#2083F3" }}></FaCss3>
                        <FaReact size={80} style={{ color: "#2083F3" }}></FaReact>
                        <FaLaravel size={80} style={{ color: "#F33020" }}></FaLaravel>
                        <FaNpm size={80} style={{ color: "#B3ABB8" }}></FaNpm>
                        <FaBootstrap size={80} style={{ color: "#A020F3" }}></FaBootstrap>
                    </div>
                </div>
                <Divider></Divider>
                <Carousel cols={2} rows={1} gap={20} loop autoplay={3500} showDots={true} responsiveLayout={layout}>
                    {
                        Record.map((img) => {
                            return (
                                <Carousel.Item>
                                    <Image src={img.image} preview width='100%' />
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
                <Divider></Divider>
                <Link to={'/projects'}><Button className='p-button-sm p-button-info' label='Return Page'></Button></Link>
                
            </div>

        </React.Fragment>
    )
}

export default Cocobid