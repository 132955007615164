import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import React from 'react'
import Records from '../../Images/Json/Faclhion.json'
import Carousel from 'react-grid-carousel'
import { Divider } from 'primereact/divider'
import { Link } from 'react-router-dom'
import { FaUnity } from 'react-icons/fa'
import { Image } from 'primereact/image'


function Falchion() {

    const layout = [
        {
            breakpoint: 800,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 1500,
            showDots: true,
        }
    ]


    return (
        <React.Fragment>
            <div data-aos="">
                <div id='falchion' >
                    <Badge value="3"></Badge>
                    <div className="container">
                        <h4 className='text-white sm:text-base md:text-lg text-justify'> RPG 2D Game Android Falchion Using Unity Software <Badge value="Underdevelopment" severity="danger"></Badge></h4>
                    </div>
                    <div className="text-container mt-3">
                        <p className='text-parag'>Falchion is a simple classic Role-Playing Game where you can play as a knight, Enter Unexplored Dungeons,Accept quests,kill monsters and gather coins to buy more powerful items.</p>
                        <div className="container">
                            <Divider><h4 className='text-white'>Features</h4></Divider>
                            <ul>
                                <li className='mb-3 text-secondary'><span>Inventory system</span></li>
                                <li className='mb-3 text-secondary'><span>Quests</span></li>
                                <li className='mb-3 text-secondary'><span>Buy some items from NPC</span></li>
                                <li className='mb-3 text-secondary'><span>Grid map based for open world RPG and character selection menu</span></li>
                            </ul>
                            <Divider><h4 className='text-white'>Tools & Language</h4></Divider>
                            <div className="container mx-auto">
                                    <div className="grid grid-cols-4 grid-flow-row-dense gap-2 grid-rows-3">
                                    <FaUnity size={80} style={{ color: "white" }}></FaUnity>
                                    
                                    </div>
                                </div>
                        </div>
                        <Carousel cols={2} rows={1} gap={20} loop autoplay={3500} showDots={true} responsiveLayout={layout}>
                            {
                                Records.map((img) => {
                                    return (
                                        <Carousel.Item>
                                            <Image src={img.image} preview width='100%' />
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </div>
                <div className="container">
                    <div className="sm:flex gap-x-4 sm:flex-col sm:gap-y-3">
                        <a href="/WebGL/index.html" target="_blank" rel='noreferrer' className='no-underline'><Button className='p-button-info p-button-sm p-button-danger' icon="pi pi-fw pi-play" label='Game Play'></Button> </a>
                        <a href="https://www.youtube.com/watch?v=q4Qpm_2eSOo&t=3s" target="_blank" rel='noreferrer' className='no-underline'><Button className='p-button-info p-button-sm' label='Play Video'></Button> </a>
                        <Link to={'/projects'}><Button className='p-button-sm p-button-info'>Return Page</Button></Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Falchion