import React from 'react'
import { Link } from 'react-router-dom'

function Page404() {
    return (
        <div>Page404

            <Link to={'/'}>Return Page</Link>
        </div>
    )
}

export default Page404