import { Badge } from 'primereact/badge'
import { Divider } from 'primereact/divider'
import React from 'react'
import Carousel from 'react-grid-carousel'
import Record from '../../Images/Json/Archives.json'
import { FaBootstrap, FaCss3, FaHtml5, FaLaravel, FaNpm, FaReact } from 'react-icons/fa'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { Image } from 'primereact/image'

function Archives() {

    const layout = [
        {
            breakpoint: 800,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 1500,
            showDots: true,
        }
    ]

    return (
        <React.Fragment>
            <div id='archives'>
                <div className="container mx-auto">
                    <Badge value="2"></Badge>
                    <h4 className='text-white sm:text-base md:text-lg text-justify'>Web-based Thesis & Research Archive for College Students in Father Saturnino Urios University</h4>
                    <p className='text-parag'>To develop an archive system for thesis papers in order to eliminate the physical storage of hard copies. Also, to provide college students of Father Saturnino Urios University (FSUU) with access to past thesis papers to acquire reliable information relevant to related topics.</p>
                </div>
                <Divider>   <h4 className='text-white'>Features & Reference</h4></Divider>
             
                <div className="container">
                    <div className="grid md:grid-cols-4 gap-3 md:grid-rows-3 sm:grid-cols-1 grid-flow-row-dense">
                        <div className='text-white'>Search Engine</div>
                        <div className='text-white'>Integrate API's (Gmail,Recapcha)</div>
                        <div className='text-white'>Stored Thesis Files</div>
                        <div className='text-white'>Booking System</div>
                        <div className='text-white'>Data Analytics</div>
                        <div className='text-white'>Mendeley</div>
                        <div className='text-white'>Google Scholar</div>
                    </div>
                </div>
                <Divider><h4 className='text-white'>Tools & Language</h4></Divider>
                
                <div className="container mx-auto">
                    <div className="grid grid-cols-4 grid-flow-row-dense gap-4 grid-rows-3">
                        <FaHtml5 className='' size={80} style={{ color: "#F36620" }}></FaHtml5>
                        <FaCss3 size={80} style={{ color: "#2083F3" }}></FaCss3>
                        <FaReact size={80} style={{ color: "#2083F3" }}></FaReact>
                        <FaLaravel size={80} style={{ color: "#F33020" }}></FaLaravel>
                        <FaNpm size={80} style={{ color: "#B3ABB8" }}></FaNpm>
                        <FaBootstrap size={80} style={{ color: "#A020F3" }}></FaBootstrap>
                    </div>
                </div>
                <Divider></Divider>
                <Carousel cols={2} rows={1} gap={20} loop autoplay={3500} showDots={true} responsiveLayout={layout}>
                    {
                        Record.map((img) => {
                            return (
                                <Carousel.Item>
                                        <Image src={img.image} preview width='100%' />
                                    {/* <img src={img.image} alt="" width="100%" /> */}
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
                <Divider></Divider>
                <Link to={'/projects'}><Button className='p-button-sm p-button-info' label='Return Page'></Button></Link>
                
            </div>

        </React.Fragment>
    )
}

export default Archives