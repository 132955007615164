import React from 'react'
import { Menubar } from 'primereact/menubar';
import {Avatar} from 'primereact/avatar'
import img1 from '../Images/icon.svg';
import { FaCode } from 'react-icons/fa';


function NavBar() {


    const items = [
        {
            label: 'About Me',
            icon: 'pi pi-fw pi-user',
            url: "/",
        },
        {
            label: 'Projects',
            icon: 'pi pi-fw pi-folder',
            url: "/projects",
        },
        {
            label: 'Tools & Programming Language',
            icon: 'pi pi-fw pi-cog',
            url: "/tools",
        },
        
       
       
      
    ];

    const tag = <div className='px-1'></div>
    return (
        <div id='home'>

            <Menubar
                model={items}
                start={tag}
                className="shadow-2xl fixed w-full p-2"
            />

        </div>
    )
}

export default NavBar