import AboutMe from "../AboutMe";
import Project from "../Project";
import Archives from "../Project/Archives";
import BFP from "../Project/BFP";
import Chatbot from "../Project/Chatbot";
import Cocobid from "../Project/Cocobid";
import Enhancement from "../Project/Enhancement";
import Falchion from "../Project/Falchion";
import Invemtory from "../Project/Invemtory";
import Thesis from "../Project/Thesis";
import ToolsProgramming from "../ToolsProgramming";



const PublicRoutes = [
    {path: '/', exact: true, name: "Index", components: AboutMe},
    {path: '/projects', exact: true, name: "Projects", components: Project},
    {path: '/BFP', exact: true, name: "BFP", components: BFP},
    {path: '/archives', exact: true, name: "Aechives", components: Archives},
    {path: '/cocobid', exact: true, name: "Cocobid", components: Cocobid},
    {path: '/thesis', exact: true, name: "Thesis", components: Thesis},
    {path: '/falchion', exact: true, name: "Fal", components: Falchion},
    {path: '/chatbot', exact: true, name: "Chat", components: Chatbot},
    {path: '/tools', exact: true, name: "Tool", components: ToolsProgramming},
    {path: '/inventory', exact: true, name: "Tool", components: Invemtory},
    {path: '/enhancement', exact: true, name: "Enhancement", components: Enhancement},
];

export default PublicRoutes;