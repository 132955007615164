import React from 'react';
import {FaBootstrap,FaCss3,FaHtml5,FaJs,FaLaravel,FaLinux,FaNpm,FaReact,FaUbuntu,FaUnity,FaWindows,} from 'react-icons/fa';

function ToolsProgramming() {
    return (
        <div className='flex flex-col gap-8'>
            <h4 className='text-slate-300 text-xl mb-4'>Tools And Programming</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <FaJs size={100} style={{ color: "#F3C920" }} />
                    <FaLaravel size={100} style={{ color: "#F33020" }} />
                    <FaNpm size={100} style={{ color: "#B3ABB8" }} />
                    <FaUnity size={100} style={{ color: "white" }} />
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <FaHtml5 size={100} style={{ color: "#F36620" }} />
                    <FaCss3 size={100} style={{ color: "#2083F3" }} />
                    <FaReact size={100} style={{ color: "#2083F3" }} />
                    <FaBootstrap size={100} style={{ color: "#A020F3" }} />
                </div>
            </div>

            <h4 className='text-slate-300 text-xl mb-4'>Operating System</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="grid grid-cols-3 gap-4">
                    <FaUbuntu size={100} style={{ color: "#F36620" }} />
                    <FaLinux size={100} style={{ color: "white" }} />
                    <FaWindows size={100} style={{ color: "white" }} />
                </div>
            </div>
        </div>
    );
}

export default ToolsProgramming;
