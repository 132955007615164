import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import React from 'react'
import { FaBootstrap, FaCss3, FaHtml5, FaLaravel, FaNpm, FaReact } from 'react-icons/fa'
import { Link } from 'react-router-dom'


function Enhancement(props) {


    return (
        <React.Fragment>
            <div id='project1'>
                <div className="container mx-auto mt-14">
                    <div className="grid grid-flow-row-dense grid-cols-1 ">
                        <div className="shadow-2xl overflow-hidden max-w-full">
                            <div className="container">
                                <Badge value="1"></Badge>
                                <h4 className='text-white sm:text-base md:text-lg text-justify'>Enhancing Creativity through AI: A Development of Auto-generated Floral Design and Ordering System</h4>
                                <p className='text-parag'>This study focuses on generating a floral design and an ordering system that can develop algorithms and a user-friendly interface to facilitate personalized and innovative floral arrangements. It includes considerations for diverse floral compositions, user preferences, and seamless integration with an efficient ordering system. The study also aims to assess the impact of the AI system on creativity, efficiency, and customer satisfaction in the floral design and ordering process.</p>
                                <Divider><h4 className='text-white'>Features</h4></Divider>

                                <div className="container">
                                    <div className="grid md:grid-cols-4 gap-3 md:grid-rows-3 sm:grid-cols-1 grid-flow-row-dense">
                                        <div className='text-white'>Generate Floral Design By AI</div>
                                        <div className='text-white'>Integrate API's (Gmail,Recapcha)</div>
                                        <div className='text-white'>Ordering System</div>
                                        <div className='text-white'>User Friendly, User Experience</div>
                                        <div className='text-white'>Improve Efficiency for Florists</div>
                                        <div className='text-white'>Measure and Optimize Performance</div>
                                    </div>
                                </div>
                                <Divider> <h4 className='text-white'>Tools & Language</h4></Divider>

                                <div className="container mx-auto">
                                    <div className="grid grid-cols-4 grid-flow-row-dense gap-2 grid-rows-3">
                                        <FaHtml5 className='' size={80} style={{ color: "#F36620" }}></FaHtml5>
                                        <FaCss3 size={80} style={{ color: "#2083F3" }}></FaCss3>
                                        <FaReact size={80} style={{ color: "#2083F3" }}></FaReact>
                                        <FaLaravel size={80} style={{ color: "#F33020" }}></FaLaravel>
                                        <FaNpm size={80} style={{ color: "#B3ABB8" }}></FaNpm>
                                        <FaBootstrap size={80} style={{ color: "#A020F3" }}></FaBootstrap>
                                    </div>
                                </div>

                                <div className=''>
                                    <Link to={'/projects'}><Button className='p-button-sm p-button-info' label='Return Page'></Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default Enhancement